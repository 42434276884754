import React from "react";
import shockingImg from "assets/images/shocking-vector.svg";

export const AccountDeletionConfirmModalBody = ({ onDelete, handleBack }) => {
  return (
    <>
      <img src={shockingImg} alt="" />
      <p className="delete-confirm-text">
        You want to delete your account Permanently.
      </p>
      <div className="modal-action">
        <div className="modal-action btn_password">
          <div className="button-group d-flex justify-content-center align-items-center">
            <button className="btn btn-secondary" onClick={handleBack}>
              Close
            </button>
            <button className="btn btn-primary" onClick={onDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
