import React, { useRef, useState } from "react";
import InputControl from "shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";

import activationCode from "assets/images/activationCode.svg";
import ArrowRightIconWhite from "assets/images/icons/right-arrow.svg";
import { FormFeedback } from "reactstrap";

export const DashboardActivationCode = ({
  activationCodes,
  addActivationCode,
  setActivationCode,
  showError,
  setShowError,
}) => {
  const [activationState, setActivationState] = useState(0);
  const activationValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setActivationState },
    })
  );

  const errorMessage = activationValidator.current.message(
    "Activation Code",
    activationCodes,
    "required"
  );

  const handleChange = (e) => {
    setActivationCode((draft) => {
      draft = e.target.value;
      return draft;
    });
    setShowError((draft) => {
      draft = "";
      return draft;
    });
  };

  const onActivateCode = () => {
    if (activationValidator.current.allValid()) {
      addActivationCode.mutate({
        activationCodes,
      });
    } else {
      activationValidator.current.showMessages();
      setActivationState((prev) => prev + 1);
    }
  };

  return (
    <div className="row no_program_parent mx-0">
      <div className="col-xs-12 col-sm-6 d-flex flex-column justify-content-center p-4">
        <p className="activation_code_title">
          No programs are available for you right now
        </p>
        <p className="pb-0">
          Unlock access to new programs or courses by entering the code provided
          to you.
        </p>
        <div className="d-flex gap-3 pt-3">
          <div>
            <InputControl
              type="text"
              id="activation-code"
              className="form-control activation_form_control"
              name="Activation Code"
              placeholder="Activation Code"
              value={activationCodes}
              onChange={handleChange}
              invalid={showError ? true : errorMessage}
            />
            <FormFeedback style={{ textAlign: "start" }}>
              {showError ? "Entered code is not correct" : errorMessage}
            </FormFeedback>
          </div>
          <button
            className="btn btn-nergy-primary btn-activation_code"
            onClick={onActivateCode}
            disabled={addActivationCode?.isPending}
          >
            <span className="btn-txt">Activate</span>
            <span className="btn-arrow">
              <img src={ArrowRightIconWhite} />
            </span>
          </button>
        </div>
      </div>
      <div className="col-sm-6 d-none d-sm-flex d-md-flex justify-content-center">
        <img src={activationCode} height={"320px"} />
      </div>
    </div>
  );
};
