import { useImmer } from "use-immer";
import {
  authenticate,
  IsUserExist,
  resendInvitationEmail,
  userSignOut,
  useAppScope,
} from "..";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  setCookie,
  removeCookie,
  errorFormatter,
  successMessage,
  clearHistoryAndRedirect,
  clearLocalStorage,
} from "utils";
import { useModal } from "shared";
import { COOKIE_EXPIRY_DAYS } from "master";

export const useAuthenticate = () => {
  const navigate = useNavigate();
  const { setAppState, isRemember } = useAppScope();
  const { modalState, onOpenModal, onCloseModal } = useModal();
  const [authenticateState, setAuthenticateState] = useImmer({
    credential: { userName: "", password: "" },
    isInvitation: false,
    isEmailExist: false,
    isValidate: false,
  });

  const isUserEmailExist = useMutation({
    mutationFn: IsUserExist,
    onSuccess: (data) => {
      if (data === 2 || data === 3) {
        setAuthenticateState((draft) => {
          draft.isInvitation = true;
          return draft;
        });
      } else {
        setAuthenticateState((draft) => {
          draft.isEmailExist = true;
          return draft;
        });
      }
    },
    onError: (e) => {
      onOpenModal({
        actionFrom: "invalidEmail",
        info: e.response.data.message,
      });
    },
  });

  const authenticateUser = useMutation({
    mutationFn: authenticate,
    onSuccess: (data) => {
      setAuthenticateState((draft) => {
        draft.isValidate = true;
        return draft;
      });
      if (data.token) {
        setAppState((draft) => data);
        const { refreshToken, token, user } = data;
        const { permissions, ...rest } = user;
        const userData = {
          refreshToken,
          token,
          user: { ...rest },
        };

        if (isRemember) {
          setCookie(
            "_stu_user_data",
            JSON.stringify(userData),
            COOKIE_EXPIRY_DAYS
          );
        } else {
          setCookie("_stu_user_data", JSON.stringify(userData));
        }
        navigate("/", { replace: true });
      }
    },
    onError: (e) => {
      onOpenModal({
        actionFrom: "invalidPassword",
        info: "The password you entered is incorrect.\nplease try again.",
      });
    },
  });

  const signOutFromAccount = useMutation({
    mutationFn: userSignOut,
    onSuccess: () => {
      clearHistoryAndRedirect("/login");
    },
    onError: (e) => {
      errorFormatter(e);
      clearHistoryAndRedirect("/login");
    },
    onSettled: () => {
      removeCookie("_stu_user_data");
      sessionStorage.clear();
      clearLocalStorage();
    },
  });

  const resendInvitation = useMutation({
    mutationFn: resendInvitationEmail,
    onSuccess: (data) => {
      successMessage(data.message);
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  const onUserSignOut = () => {
    signOutFromAccount.mutate();
  };

  return {
    authenticateState,
    setAuthenticateState,
    authenticateUser,
    signOutFromAccount,
    onUserSignOut,
    isUserEmailExist,
    modalState,
    onOpenModal,
    onCloseModal,
    resendInvitation,
  };
};
