import React, { useEffect } from "react";
import { GET_PROGRAM_GROUP, getCourseTaskGroup } from "..";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getSelectedProgram } from "utils";

export const useLearningMaterials = ({ load = false, type = null }) => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("currentTask");
    if (type === null || !sessionStorage?.getItem("courseList")) {
      navigate("/program");
      sessionStorage.removeItem("courseGroup");
      sessionStorage.removeItem("courseList");
    }
  }, []);

  let selectedProgram = getSelectedProgram();
  let { studentBatchId, name: selectedProgramName } =
    !!selectedProgram && selectedProgram;

  const programGroupList = useQuery({
    queryKey: [GET_PROGRAM_GROUP, studentBatchId, type],
    queryFn: () => getCourseTaskGroup({ studentBatchId, type }),
    enabled: load,
  });

  let courseListStorage = sessionStorage.getItem("courseList")
    ? JSON.parse(sessionStorage.getItem("courseList"))
    : null;
  let { hasCourseGroup, displayName } =
    !!courseListStorage && courseListStorage;

  return {
    studentBatchId,
    selectedProgramName,
    programGroupList,
    hasCourseGroup,
    displayName,
    courseType: type,
  };
};
