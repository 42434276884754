import React, { useState, useRef } from "react";
import { Spinner } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  ConfirmRegistrationModalBody,
  DashboardActivationCode,
  ProgramCard,
  useDashboard,
} from "..";
import { LoadingSpinner, ModalLayout, useProfileDropDown } from "shared";
import parse from "html-react-parser";

import ConfirmIconGraphics from "assets/images/confirm-icon-graphics.svg";
import InvalidIconGraphics from "assets/images/invalid-icon-graphics.svg";
import checkGraphics from "assets/images/check-graphics.svg";

export const DashboardPrograms = () => {
  const {
    program,
    isProgramLoading,
    modalState,
    onOpenModal,
    onCloseModal,
    activateBatchMutation,
    acceptTerm,
    regNo,
    setRegNo,
    setCurrentProgram,
  } = useDashboard();

  const {
    addActivationCode,
    activationCodes,
    setActivationCode,
    modalState: profileModalState,
    onCloseModal: profileOnCloseModal,
    showError,
    setShowError,
  } = useProfileDropDown({ load: false });

  const [update, forceUpdate] = useState(0);

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onActivationModalClose = () => {
    setRegNo(null);
    validator.current.hideMessages();
    onCloseModal();
  };

  const onActivateBatch = () => {
    if (validator.current.allValid()) {
      activateBatchMutation.mutate({
        studentBatchId: modalState.info,
        regNo,
      });
    } else {
      validator.current.showMessages();
      forceUpdate((prev) => prev + 1);
    }
  };

  const onAcceptTearms = () => {
    acceptTerm.mutate({
      termsId: modalState.info.terms.termsId,
      studentBatchId: modalState.info.studentBatchId,
    });
  };

  if (isProgramLoading) {
    return <LoadingSpinner />;
  }

  const isModalSubmitting =
    activateBatchMutation?.isPending ||
    acceptTerm?.isPending ||
    isProgramLoading;

  return (
    <>
      {program && program?.length > 0 ? (
        <div className="featured-section">
          <div className="featured-section-header">
            <h2>Programs</h2>
          </div>
          <div className="featured-list d-md-flex justify-content-md-start flex-md-wrap">
            {program?.map((element) => {
              return (
                <ProgramCard
                  key={`dashboardProgramCard-${element.id}`}
                  data={element}
                  onOpenModal={onOpenModal}
                  activateBatchMutation={activateBatchMutation}
                  setCurrentProgram={setCurrentProgram}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <DashboardActivationCode
          addActivationCode={addActivationCode}
          activationCodes={activationCodes}
          setActivationCode={setActivationCode}
          showError={showError}
          setShowError={setShowError}
        />
      )}
      {modalState.actionFrom === "confirmRegistration" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={
            modalState.isError
              ? "Invalid registration number"
              : "Confirm Registration"
          }
          message={
            modalState.isError
              ? "Please enter a valid registration number"
              : "Enter your registration number to continue your learning"
          }
          onConfirm={onActivateBatch}
          onCancel={onActivationModalClose}
          icon={modalState.isError ? InvalidIconGraphics : ConfirmIconGraphics}
          modalStyle={{ maxWidth: "330px", margin: "auto" }}
          modalBody={
            <ConfirmRegistrationModalBody
              regNo={regNo}
              setRegNo={setRegNo}
              activateBatchMutation={activateBatchMutation}
              ref={validator}
            />
          }
          modalButtonGroup={
            <div className="button-group d-flex justify-content-center align-items-center">
              <button
                className="btn btn-secondary"
                onClick={onActivationModalClose}
                disabled={isModalSubmitting}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={onActivateBatch}
                disabled={isModalSubmitting}
              >
                {isModalSubmitting ? (
                  <Spinner size="sm">Loading...</Spinner>
                ) : modalState.isError ? (
                  "Try again"
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          }
        />
      )}

      {modalState.actionFrom === "registrationSuccess" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={"Registration successful!"}
          message={"You have successfully registered great."}
          onConfirm={onCloseModal}
          onCancel={onCloseModal}
          confirmButtonLabel={"ok"}
          icon={checkGraphics}
        />
      )}
      {profileModalState.actionFrom === "activationSuccess" && (
        <ModalLayout
          isOpen={profileModalState.isOpen}
          title={"Congratulations"}
          message={"Your Course has been Activated."}
          onConfirm={profileOnCloseModal}
          onCancel={profileOnCloseModal}
          confirmButtonLabel={"ok"}
          icon={checkGraphics}
        />
      )}

      {modalState.actionFrom === "acceptTerms" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          header={modalState.info.terms.name}
          modalBody={
            <div className="ql-editor">
              {parse(modalState.info.terms.content)}
            </div>
          }
          modalStyle={{ maxWidth: "600px" }}
          customClass={"program-terms-modal"}
          onCancel={onCloseModal}
          modalButtonGroup={<></>}
          modalMainFooter={
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-nergy-primary"
                onClick={onAcceptTearms}
                disabled={isModalSubmitting}
              >
                Ok
              </button>
            </div>
          }
        />
      )}
    </>
  );
};
