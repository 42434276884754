import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ProfileDropDown,
  NotificationDropDown,
  ChangePassword,
  AssessmentTimer,
  ActivationCode,
} from "modules";
import { HeaderLink, ModalLayout, useProfileDropDown } from "shared";
import { useQuestionareContext, useAppScope, useAuthenticate } from "master";
import NotificationIcon from "assets/images/icons/notifiction-icon.svg";
import UserIcon from "assets/images/icons/user-icon.svg";
import InstructionIcon from "assets/images/icons/book-dark-grey-thick-icon.svg";
import HelpIcon from "assets/images/icons/question-icon-thick-grey.svg";
import warningIcon from "assets/images/icons/warning-round-filled-blue.svg";
import checkGraphics from "assets/images/check-graphics.svg";
import {
  clearHistoryAndRedirect,
  clearLocalStorage,
  removeCookie,
} from "utils";

export const HeaderRightBlock = () => {
  const location = useLocation();
  const questionnaireContext = useQuestionareContext();
  const { signOutFromAccount } = useAuthenticate();
  const { onToggleInstruction, onToggleHelp } = questionnaireContext;
  const isQuestionnairePages =
    location.pathname === "/program/assessment/start" ||
    location.pathname === "/program/assessment/review" ||
    location.pathname === "/program/assessment/result" ||
    location.pathname === "/program/assessment/expired" ||
    location.pathname === "/program/assessment/allQuestions";

  const isAssesmentResult = location.pathname === "/program/assessment/result";

  const { AppState } = useAppScope();
  const isExternal = !!AppState?.isExternal;
  const {
    user: { firstName, lastName },
    registrationId,
  } = AppState;

  const studentName = `${firstName} ${lastName ? lastName : ""}`;

  const {
    modalState,
    onOpenModal,
    onCloseModal,
    profileQuery,
    addActivationCode,
    activationCodes,
    setActivationCode,
    showError,
    setShowError,
  } = useProfileDropDown({ load: !isQuestionnairePages });

  useEffect(() => {
    onCloseModal();
  }, [location.pathname]);

  const onSignOut = () => {
    if (isExternal) {
      clearHistoryAndRedirect("/login");
      removeCookie("_stu_user_data");
      sessionStorage.clear();
      clearLocalStorage();
    } else {
      signOutFromAccount.mutate();
    }
  };

  return (
    <>
      {isQuestionnairePages ? (
        <>
          {!isAssesmentResult && (
            <>
              <AssessmentTimer />
              <div className="header-info-group">
                <button
                  className="header-info-item"
                  onClick={() => onToggleInstruction(null, "header")}
                >
                  <img src={InstructionIcon} />
                </button>
                <button
                  className="header-info-item"
                  onClick={() => onToggleHelp()}
                >
                  <img src={HelpIcon} />
                </button>
              </div>
              <div className="user-info-block">
                <h3 className="user-name">{studentName}</h3>
                {!!registrationId && (
                  <span className="user-reg-number">{`Reg No:${registrationId}`}</span>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <HeaderLink icon={NotificationIcon} location={location}>
            <NotificationDropDown />
          </HeaderLink>
          <HeaderLink
            icon={UserIcon}
            location={location}
            closeDropDown={modalState.isOpen}
          >
            <ProfileDropDown
              onOpenModal={onOpenModal}
              profileQuery={profileQuery}
            />
          </HeaderLink>
          {modalState.actionFrom == "changePassword" && (
            <ChangePassword
              openChangePassword={modalState.isOpen}
              onCloseModal={onCloseModal}
            />
          )}
          {modalState.actionFrom == "activationCode" && (
            <ActivationCode
              isOpen={modalState.isOpen}
              onCloseModal={onCloseModal}
              addActivationCode={addActivationCode}
              activationCodes={activationCodes}
              setActivationCode={setActivationCode}
              showError={showError}
              setShowError={setShowError}
            />
          )}
          {modalState.actionFrom === "activationSuccess" && (
            <ModalLayout
              isOpen={modalState.isOpen}
              title={"Congratulations"}
              message={"Your Course has been Activated."}
              onConfirm={onCloseModal}
              onCancel={onCloseModal}
              confirmButtonLabel={"ok"}
              icon={checkGraphics}
            />
          )}
          {modalState.actionFrom === "logoutModal" && (
            <ModalLayout
              isOpen={modalState.isOpen}
              title={"Confirm logout"}
              message={"Are you sure you want to log out?"}
              icon={warningIcon}
              onCancel={onCloseModal}
              modalButtonGroup={
                <div className="button-group d-flex justify-content-center align-items-center">
                  <button className="btn btn-secondary" onClick={onCloseModal}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={signOutFromAccount.isPending}
                    onClick={onSignOut}
                  >
                    Log out
                  </button>
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
};
