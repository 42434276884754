import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ModalLayout } from "shared";
import { ActivationCodeModalBody } from ".";

import closeIcon from "assets/images/icons/close-icon-white-round.svg";
import "../styles/activationCode.styles.css";

export const ActivationCode = ({
  isOpen,
  onCloseModal,
  addActivationCode,
  activationCodes,
  setActivationCode,
  showError,
  setShowError,
}) => {
  const [update, forceUpdate] = useState(0);

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  useEffect(() => {
    setActivationCode((draft) => {
      draft = "";
      return draft;
    });
  }, [isOpen]);

  const onActivateCode = () => {
    if (validator.current.allValid()) {
      addActivationCode.mutate({
        activationCodes,
      });
    } else {
      validator.current.showMessages();
      forceUpdate((prev) => prev + 1);
    }
  };

  const handleClose = () => {
    setShowError((draft) => {
      draft = "";
      return draft;
    });
    onCloseModal();
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      modalStyle={{ maxWidth: "450px" }}
      HeaderComponent={
        <div class="modal-header d-flex justify-content-end border-0 px-2 py-3">
          <button className="activation_close_action" onClick={handleClose}>
            <img src={closeIcon} />
          </button>
        </div>
      }
      customClass={"activation_code"}
      modalBody={
        <ActivationCodeModalBody
          ref={validator}
          activationCodes={activationCodes}
          setActivationCode={setActivationCode}
          onActivateCode={onActivateCode}
          addActivationCode={addActivationCode}
          showError={showError}
          setShowError={setShowError}
        />
      }
      modalButtonGroup={<></>}
    />
  );
};
