import { usePostHog } from "posthog-js/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageViewTracker = () => {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview");
    }
  }, [posthog, location.pathname]);

  return null;
};
