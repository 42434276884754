import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Master } from "master";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const hostname = window.location.hostname;
const isLocal =
  hostname === "localhost" ||
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(hostname);
const appENV_KEY = process.env.REACT_APP_ENVIORNMENT_KEY;
const isProduction = appENV_KEY === "production" || appENV_KEY === "gtec";

if (!isLocal) {
  Sentry.init({
    dsn: "https://b8325a8c42354ea98e0aad184caeb378@o4504909936001024.ingest.us.sentry.io/4504915457736704",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: appENV_KEY,
  });
  if (isProduction) {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    });
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Master />
    </PostHogProvider>
  </React.StrictMode>
);
