import React from "react";
import { LoadingSpinner } from "shared";
import { useHandleExternalToken } from "..";

export const External = () => {
  useHandleExternalToken();
  const brandLogo = process.env.REACT_APP_MAIN_LOGO;

  return (
    <>
      <header className="auth-header">
        <div className="container">
          <div className="row">
            <div className="header-block d-flex align-items-center justify-content-between">
              <span className="brand-logo">
                <img src={brandLogo} alt="nergy vidya" />
              </span>
            </div>
          </div>
        </div>
      </header>
      <LoadingSpinner />
    </>
  );
};
