import { Axios } from "utils";

export const getAssessmentList = async (studentBatchId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/batches/${studentBatchId}`
  );
  return res.data;
};

export const getAssessmentSchedule = async ({
  assessmentId,
  studentBatchId,
}) => {
  const res = await Axios.get(
    `/v2/portal/assessments/${assessmentId}/batches/${studentBatchId}/schedules`
  );
  return res.data;
};

export const getAssessmentSlot = async (scheduleId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/schedules/${scheduleId}/slots`
  );
  return res.data;
};

export const createAssessmentSchedule = async ({ slotId }) => {
  const res = await Axios.post(
    `/v2/portal/assessments/${slotId}/create-schedule`
  );
  return res.data;
};

export const assessmentResult = async (assessmentMasterId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/${assessmentMasterId}/result`,
    {
      headers: {
        "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
      },
    }
  );
  return res.data;
};
