import { Modal, ModalHeader, Spinner } from "reactstrap";
import "../../index.css";

export const ModalLayout = (props) => {
  const {
    isOpen,
    title = "",
    largeTitle = "",
    message = "",
    onCancel,
    onConfirm,
    confirmButtonLabel = "Submit",
    icon = "",
    iconClass = "",
    modalFooter = null,
    modalMainFooter = null,
    modalBody = null,
    modalButtonGroup = null,
    modalStyle = { maxWidth: "400px" },
    header = "",
    HeaderComponent = null,
    isLoading = false,
    customClass = "",
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      centered
      scrollable
      className={`common-modal ${customClass}`}
      style={modalStyle}
    >
      {header && <ModalHeader>{header}</ModalHeader>}
      {HeaderComponent && HeaderComponent}
      <div className="modal-body common-modal-content">
        {icon && (
          <img
            src={icon}
            alt=""
            className={iconClass ? iconClass : "modal-icon"}
          />
        )}
        {largeTitle && <h1>{largeTitle}</h1>}
        {title && <h2>{title}</h2>}
        {message && <p>{message}</p>}
        {modalBody && modalBody}
        <div className="modal-action">
          {!modalButtonGroup && (
            <button
              type="button"
              onClick={onConfirm}
              className="btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner size="sm">Loading...</Spinner>
              ) : (
                confirmButtonLabel
              )}
            </button>
          )}
          {modalButtonGroup && modalButtonGroup}
        </div>

        {modalFooter && (
          <div className="modal-footer-content">{modalFooter}</div>
        )}
      </div>
      {modalMainFooter && (
        <div className="modal-footer-content">{modalMainFooter}</div>
      )}
    </Modal>
  );
};
