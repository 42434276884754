import React from "react";
import greenCheckedFilled from "assets/images/icons/green-check-filled.svg";
import { CommonProgressBar } from "shared";
import { formatDuration } from "utils";
import moment from "moment";

export const CourseGroupCard = ({
  element,
  onClick,
  isAssignment,
  isTheory,
  displayDuration,
}) => {
  const {
    name,
    hasPassed,
    isActive,
    startDate,
    endDate,
    totalDuration,
    numberOfCourses,
    taskProgressPercentage,
  } = element;
  const showDuration = isAssignment && displayDuration;
  const passed = hasPassed === false && isAssignment;

  const formattedDate = (date) => {
    return date ? moment(date)?.format("DD-MM-YYYY") : "--";
  };

  return (
    <div
      className={`grid-item mini-grid-item ${isActive ? "" : "disabled"}`}
      onClick={onClick}
      aria-disabled={!isActive}
    >
      <div className="grid-item-content">
        <h2>{name}</h2>
        {!isTheory && (
          <>
            <div className="grid-sub-text">{numberOfCourses} Courses</div>
            {startDate && endDate && (
              <div className="d-flex gap-3 mt-2">
                <div className="grid-sub-date">
                  <p>Start Date</p>
                  <p>{formattedDate(startDate)}</p>
                </div>
                <div className="grid-sub-date">
                  <p>End Date</p>
                  <p>{formattedDate(endDate)}</p>
                </div>
              </div>
            )}
          </>
        )}
        {!isTheory && (
          <>
            {taskProgressPercentage === 100 ? (
              <div className="complete-badge">
                <img src={greenCheckedFilled} />
                <span>Completed</span>
              </div>
            ) : (
              <CommonProgressBar
                width={taskProgressPercentage}
                label={taskProgressPercentage + "%"}
                hasPassed={passed}
              />
            )}
          </>
        )}
        {showDuration && (
          <span>Total Duration: {formatDuration(totalDuration)} </span>
        )}
      </div>
    </div>
  );
};
