import { activationCode, GET_MY_PROGRAMS, useProfileQuery } from "modules";
import { useModal } from ".";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { errorFormatter } from "utils";
import { useImmer } from "use-immer";

export const useProfileDropDown = ({ load = false }) => {
  const queryClient = useQueryClient();
  const { modalState, onOpenModal, onCloseModal } = useModal();
  const [activationCodes, setActivationCode] = useImmer("");
  const [showError, setShowError] = useImmer("");

  const profileQuery = useProfileQuery({ load });

  const addActivationCode = useMutation({
    mutationFn: activationCode,
    onError: (e) => {
      if (
        e &&
        e?.response?.data &&
        e?.response?.data?.message === "Student already enrolled in program!"
      ) {
        errorFormatter(e);
      } else {
        setShowError((draft) => {
          draft = e?.response?.data?.message;
          return draft;
        });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([GET_MY_PROGRAMS]);
      setShowError((draft) => {
        draft = "";
        return draft;
      });
      onCloseModal();
      onOpenModal({
        actionFrom: "activationSuccess",
      });
    },
  });

  return {
    modalState,
    onCloseModal,
    onOpenModal,
    profileQuery,
    addActivationCode,
    activationCodes,
    setActivationCode,
    showError,
    setShowError,
  };
};
