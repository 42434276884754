import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAssessmentList, GET_ASSESSMENT_LIST } from "..";

export const useAssessmentQuery = ({ load = false, studentBatchId = null }) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_LIST, studentBatchId],
    queryFn: () => getAssessmentList(studentBatchId),
    enabled: load && !!studentBatchId,
    refetchOnWindowFocus: true,
  });
};
