import React from "react";
import { ErrorComponent, LoadingSpinner, PageLayout } from "shared";
import { CourseCard, useCourse, useLearningMaterials } from "..";
import { useNavigate } from "react-router-dom";
import ErrorImg from "assets/images/error-theory-not-found-graphics.svg";
import { getSelectedProgram } from "utils";

export const CourseList = ({ type }) => {
  const navigate = useNavigate();
  const selectedProgram = getSelectedProgram();
  const { selectedProgramName } = useLearningMaterials({ load: false, type });
  const { courseList, name, hasCourseGroup, courseType, displayName } =
    useCourse({ type });

  const { data } = courseList;
  const isAssignment = courseType === 2;
  const isTheory = courseType === 0;
  const currentName = isAssignment
    ? "Assignment"
    : isTheory
    ? "Learning Materials"
    : "Practicals";

  if (courseList.isPending || courseList.isLoading) {
    return <LoadingSpinner />;
  }

  const breadcrumb = [
    { label: selectedProgramName, location: "/program" },
    {
      label: displayName ? displayName : currentName,
      location: hasCourseGroup
        ? isAssignment
          ? "/program/assignment/coursegroup"
          : isTheory
          ? "/program/learningmaterials/coursegroup"
          : "/program/practicals/coursegroup"
        : "",
    },
  ];

  const hasLearningMaterial = data?.length > 0;

  if (hasCourseGroup) {
    breadcrumb.push({ label: name });
  }

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  return (
    <PageLayout
      title={
        hasLearningMaterial
          ? hasCourseGroup
            ? name || currentName
            : displayName || currentName
          : false
      }
      breadcrumb={breadcrumb}
    >
      <div className="row">
        <div className="col-12">
          {hasLearningMaterial ? (
            <div className="page-grid-items page-grid-items--row">
              {data?.map((element, index) => {
                return (
                  <CourseCard
                    index={index}
                    key={`couse-card-no-${index}`}
                    name={element?.name}
                    totalTasks={element?.totalTasks}
                    progress={element?.overallPercentage}
                    isAssignment={isAssignment}
                    isTheory={isTheory}
                    duration={element?.totalDuration}
                    onClick={() => navigate(`${element?.courseId}`)}
                    displayDuration={selectedProgram?.displayDuration}
                  />
                );
              })}
            </div>
          ) : (
            <ErrorComponent
              title={`No Course Found!`}
              message={`There is no course assigned for you at the moment.`}
              image={ErrorImg}
              btnLabel="Go back"
              onClick={goToPreviousPage}
            />
          )}
        </div>
      </div>
    </PageLayout>
  );
};
