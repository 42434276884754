import { Axios } from "utils";

export const getMyPrograms = async () => {
  const res = await Axios.get("/v2/portal/programs/my-programs");
  return res.data;
};

export const activateBatch = async ({ studentBatchId, regNo }) => {
  const res = await Axios.post(
    `/v2/portal/programs/batches/${studentBatchId}/Activate`,
    { regNo },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const acceptTerms = async ({ termsId, studentBatchId }) => {
  const res = await Axios.post(
    `/v2/portal/programs/terms/${termsId}/batches/${studentBatchId}`
  );
  return res.data;
};

export const getDashboardBanner = async (isMobile) => {
  const res = await Axios.get(
    `/v2/portal/Students/banners?isMobileView=${isMobile}`
  );
  return res.data;
};

export const activationCode = async ({ activationCodes }) => {
  const res = await Axios.post(
    `v2/portal/Programs/activate/${activationCodes}`
  );
  return res.data;
};
