import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { FormFeedback } from "reactstrap";
import { useImmer } from "use-immer";
import InputControl from "shared/components/InputControl";
import { strongPassword } from "utils";
import { AuthLayout, ModalLayout, PasswordToggle } from "shared";
import { useGeneratePassword } from "..";
import loginPasswrodGraphics from "assets/images/login-passwrod-graphics.svg";
import checkGraphics from "assets/images/check-graphics.svg";

export const GeneratePassword = () => {
  const { payload, setPayload, mutationFn, modalState, onCloseModal } =
    useGeneratePassword();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const emailAddress = searchParams.get("email");
  const token = searchParams.get("token");

  const { password, confirmPassword } = payload;
  const [passwordShown, setPasswordShown] = useImmer({
    password: false,
    confirmPassword: false,
  });
  const [update, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setPayload((draft) => {
      draft[name] = value;
    });
  };

  const togglePassword = (type) => {
    setPasswordShown((draft) => {
      draft[type] = !draft[type];
      return draft;
    });
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      mutationFn.mutate({
        ...payload,
        emailAddress,
        token,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const newOnCloseModal = () => {
    onCloseModal();
    navigate("/login", { replace: true });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    validator.current.showMessageFor(name);
    if (name === "password") {
      forceUpdate(2);
    } else {
      forceUpdate(3);
    }
  };

  return (
    <AuthLayout leftDivImage={loginPasswrodGraphics}>
      <form>
        <h2>Complete Sign Up</h2>
        <p className="form-auth-sm-txt">Create password for your account</p>
        <div className="mb-3 position-relative form-group has-icon-right change_password">
          <label className="form-label">New password</label>
          <InputControl
            name="password"
            type={passwordShown.password ? "text" : "password"}
            className="form-control"
            disabled={mutationFn.isPending}
            onChange={onHandleChange}
            invalid={validator.current.message(
              "password",
              password,
              "required|min:8|upper|lower|digit|special"
            )}
            onBlur={handleBlur}
          />
          <PasswordToggle
            passwordShown={passwordShown?.password}
            togglePassword={() => togglePassword("password")}
          />
          <FormFeedback>
            {validator.current.message(
              "password",
              password,
              "required|min:8|upper|lower|digit|special"
            )}
          </FormFeedback>
        </div>
        <div className="mb-3 position-relative form-group has-icon-right change_password">
          <label className="form-label">Confirm password</label>
          <InputControl
            name="confirmPassword"
            type={passwordShown.confirmPassword ? "text" : "password"}
            className="form-control"
            disabled={mutationFn.isPending}
            onChange={onHandleChange}
            invalid={validator.current.message(
              "confirmPassword",
              confirmPassword,
              `required|in:${password}`
            )}
            onBlur={handleBlur}
          />
          <PasswordToggle
            passwordShown={passwordShown?.confirmPassword}
            togglePassword={() => togglePassword("confirmPassword")}
          />
          <FormFeedback>
            {validator.current.message(
              "confirmPassword",
              confirmPassword,
              `required|in:${password}`,
              {
                messages: {
                  in: "Password and confirm password must be same!",
                },
              }
            )}
          </FormFeedback>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => onSubmit(e)}
          disabled={mutationFn.isPending}
        >
          Register
        </button>
      </form>

      {modalState.actionFrom === "passwordReset" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={"You’re good to go!"}
          message={"Your password generated successfully."}
          onConfirm={newOnCloseModal}
          onCancel={newOnCloseModal}
          confirmButtonLabel={"Go To Login"}
          icon={checkGraphics}
        />
      )}
    </AuthLayout>
  );
};
