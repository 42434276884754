import React from "react";
import { useProgramQuery } from ".";
import { getSelectedProgram } from "utils";

export const useProgram = ({ load = false }) => {
  let selectedProgram = getSelectedProgram();
  let { studentBatchId, name } = !!selectedProgram && selectedProgram;

  const programDetails = useProgramQuery({ load, studentBatchId });

  return {
    studentBatchId,
    name,
    programDetails,
  };
};
