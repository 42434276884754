import React from "react";
import { LoadingSpinner, PageLayout } from "shared";
import { CourseGroupCard, useLearningMaterials } from "..";
import { useNavigate } from "react-router-dom";
import { getSelectedProgram } from "utils";

export const CourseGroupList = ({ type = null }) => {
  const navigate = useNavigate();
  const selectedProgram = getSelectedProgram();
  const { selectedProgramName, programGroupList, displayName, courseType } =
    useLearningMaterials({
      load: true,
      type,
    });

  const { data, isLoading, isPending } = programGroupList;

  const isAssignment = courseType === 2;
  const isTheory = courseType === 0;
  const path = isAssignment
    ? "assignment"
    : isTheory
    ? "learningmaterials"
    : "practicals";

  const currentName = isAssignment
    ? "Assignment"
    : isTheory
    ? "Learning Materials"
    : "Practicals";

  const handleCourseGroup = (element) => {
    if (element?.isActive) {
      sessionStorage.setItem("courseGroup", JSON.stringify(element));
      navigate(`/program/${path}/courseslist`);
    }
  };

  if (isPending || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout
      title={displayName ? displayName : currentName}
      breadcrumb={[
        { label: selectedProgramName, location: "/program" },
        { label: displayName ? displayName : currentName },
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-grid-items page-grid-items--box">
            {data &&
              data.map((element) => {
                return (
                  <CourseGroupCard
                    key={`CourseGroupCard-${element.id}`}
                    element={element}
                    isAssignment={isAssignment}
                    isTheory={isTheory}
                    displayDuration={selectedProgram?.displayDuration}
                    onClick={() => handleCourseGroup(element)}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
