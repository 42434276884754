import React, { useEffect } from "react";
import lockIcon from "assets/images/icons/trash-icon.svg";
import { PasswordToggle } from "shared";
import InputControl from "shared/components/InputControl";
import { FormFeedback } from "reactstrap";

export const AccountDeletionModalBody = ({
  showPassWord,
  setShowPassword,
  passwordPayload,
  setPasswordPayload,
  onSubmit,
  handleBack,
  validator,
  isPasswordError,
}) => {
  const isPasswordInvalid = isPasswordError === "Invalid password!";

  useEffect(() => {
    if (isPasswordInvalid) {
      validator.current.showMessageFor("confirmPassword");
    }
  }, [isPasswordInvalid]);

  return (
    <>
      <img src={lockIcon} alt="" className={"modal-icon-small"} />
      <div className="main-heading">Account Deletion</div>
      <div className="main-content-text">
        These terms and conditions outline the rules and regulations for the use
        of Nergy India Pvt Ltd's Website, located at www.nergy360.com. By
        accessing this website we assume you accept these terms anconditions. Do
        not continue to use Nergy 360 if you do not agree to take all of the
        terms and conditions stated on this page.The following terminology
        applies to these Terms and Conditions,
      </div>
      <div className="mt-4 text-start ">
        <div className="position-relative form-group has-icon-right mb-3 change_password">
          <label className="form-label" htmlFor="conf_pass">
            Confirm password
          </label>
          <InputControl
            id="conf_pass"
            className="form-control"
            placeholder="Confirm password"
            name="confirmPassword"
            type={showPassWord ? "text" : "password"}
            value={passwordPayload}
            onChange={(e) => {
              setPasswordPayload(e.target.value);
            }}
            invalid={
              validator.current.message(
                "confirmPassword",
                passwordPayload,
                "required"
              ) || isPasswordInvalid
            }
          />
          <PasswordToggle
            passwordShown={showPassWord}
            togglePassword={() => setShowPassword((draft) => !draft)}
          />
          <FormFeedback>
            {isPasswordInvalid
              ? "The password you entered is incorrect."
              : validator.current.message(
                  "confirmPassword",
                  passwordPayload,
                  "required"
                )}
          </FormFeedback>
        </div>
      </div>
      <div className="modal-action btn_password">
        <button
          type="submit"
          className="btn btn-primary px-3"
          onClick={handleBack}
        >
          Keep My Account
        </button>
      </div>
      <div className="mt-3 btn text-danger" onClick={onSubmit}>
        Delete My Account
      </div>
    </>
  );
};
