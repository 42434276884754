import React from "react";

export const NotificationCardSkelton = () => {
  return (
    <li className="notification-item mt-2">
      <div>
        <h5 className="skeleton-text mb-2"></h5>
        <p className="skeleton-div"></p>
        <div className="notification-item-footer">
          <span
            className="skeleton skeleton-text"
            style={{ width: "60px" }}
          ></span>
          <span
            className="skeleton skeleton-text"
            style={{ width: "60px" }}
          ></span>
        </div>
      </div>
    </li>
  );
};
