import { useQuery } from "@tanstack/react-query";
import { GET_STUDENT_SPECIFIC_DATA, getStudentSpecificData } from "..";

export const useStudentSpecificDataQuery = ({
  load = false,
  studentBatchId = "",
  courseId = null,
}) => {
  return useQuery({
    queryKey: `${GET_STUDENT_SPECIFIC_DATA}_${studentBatchId}_${courseId}`,
    queryFn: () => getStudentSpecificData(studentBatchId, courseId),
    enabled: load && !!studentBatchId && !!courseId,
  });
};
