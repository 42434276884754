import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useImmer } from "use-immer";
import {
  clearHistoryAndRedirect,
  clearLocalStorage,
  errorFormatter,
  removeCookie,
} from "utils";
import { deleteAccount } from "..";

export const useAccountDeletion = () => {
  const [showPassword, setShowPassword] = useImmer(false);
  const [password, setPassword] = useImmer(null);
  const [showDeleteConformation, setShowDeleteConformation] = useImmer(false);
  const [isPasswordError, setIsPasswordError] = useImmer(null);

  useEffect(() => {
    if (isPasswordError) {
      setIsPasswordError(null);
    }
  }, [password]);

  const deleteAccountMutation = useMutation({
    mutationFn: deleteAccount,
    onError: (e) => {
      setShowDeleteConformation(false);
      if (e?.response?.data?.message === "Invalid password!") {
        setIsPasswordError("Invalid password!");
      } else {
        errorFormatter(e);
      }
    },
    onSuccess: () => {
      removeCookie("_stu_user_data");
      sessionStorage.clear();
      clearLocalStorage();
      clearHistoryAndRedirect("/login");
    },
  });

  return {
    password,
    showPassword,
    isPasswordError,
    deleteAccountMutation,
    showDeleteConformation,
    setPassword,
    setShowPassword,
    setShowDeleteConformation,
  };
};
