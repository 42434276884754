import React from "react";
import { NotificationCardSkelton } from ".";

export const NotificationDropDownSkelton = () => {
  return (
    <>
      <div className="header-card-header d-flex justify-content-between align-items-center px-4 py-3">
        <span className="skeleton skeleton-small skeleton-text"></span>
        <span className="skeleton skeleton-small skeleton-text"></span>
      </div>
      <div className="header-card-content">
        <ul className="notification-list p-3">
          <NotificationCardSkelton />
          <NotificationCardSkelton />
          <NotificationCardSkelton />
        </ul>
      </div>
    </>
  );
};
