import React from "react";
import { useNavigate } from "react-router-dom";
import arrowRightIcons from "assets/images/icons/arrow-right-icon-dark.svg";
import userProfile from "assets/images/icons/user-drop-icon.svg";
import certificateIcon from "assets/images/icons/certificate-icon-black.svg";
import activatiionCodeIcon from "assets/images/icons/activation-code-icon.svg";
import logoutIcon from "assets/images/icons/logout-icon.svg";
import "../styles/profileDropDown.styles.css";
import { getCookie } from "utils";
import { ProfileDropDownSkelton } from ".";

export const ProfileDropDown = ({ onOpenModal, profileQuery }) => {
  const navigate = useNavigate();
  const { isLoading, data } = profileQuery;
  const localData = JSON.parse(getCookie("_stu_user_data"));
  const isExternal = localData?.isExternal ? true : false;

  const handleDropDown = (value) => {
    if (value == "password") {
      onOpenModal({ actionFrom: "changePassword", info: "" });
    } else if (value == "activationCode") {
      onOpenModal({ actionFrom: "activationCode", info: "" });
    } else {
      localStorage.clear("selectedProgram");
      navigate("/certificate");
    }
  };

  return (
    <div className={`header-card header-dropdown header-user-card`}>
      {isLoading ? (
        <>
          <ProfileDropDownSkelton />
        </>
      ) : (
        <div className="header-card-content">
          <div className="user-basic-info d-flex align-items-center p-3">
            <div className="user-avatar">
              <span>
                {data?.firstName?.substring(0, 1)}
                {data?.lastName ? data.lastName.substring(0, 1) : ""}
              </span>
            </div>
            <div className="user-info-text">
              <h3 className="user-name-txt">
                {data?.firstName} {data?.lastName}
              </h3>
              <div className="user-sub-txt user-email">
                {data?.emailAddress}
              </div>
            </div>
          </div>
          <div className="actions-list">
            {!isExternal && (
              <>
                <button
                  className="action-btn"
                  onClick={() => handleDropDown("password")}
                >
                  <div className="action-btn-content">
                    <img src={userProfile} />
                    <span>Profile</span>
                  </div>
                  <span className="btn-arrow">
                    <img src={arrowRightIcons} />
                  </span>
                </button>
                <button
                  className="action-btn"
                  onClick={() => handleDropDown("activationCode")}
                >
                  <div className="action-btn-content">
                    <img src={activatiionCodeIcon} />
                    <span>Activation Code</span>
                  </div>
                  <span className="btn-arrow">
                    <img src={arrowRightIcons} />
                  </span>
                </button>
              </>
            )}
            <button
              className="action-btn"
              onClick={() => handleDropDown("certificate")}
            >
              <div className="action-btn-content">
                <img src={certificateIcon} />
                <span>Certificates</span>
              </div>
              <span className="btn-arrow">
                <img src={arrowRightIcons} />
              </span>
            </button>
            <button
              className="action-btn"
              onClick={() =>
                onOpenModal({
                  actionFrom: "logoutModal",
                })
              }
            >
              <div className="action-btn-content">
                <img src={logoutIcon} />
                <span>Log Out</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
