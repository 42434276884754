import React from "react";
import { useImmer } from "use-immer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createAssessmentSchedule,
  useAssessmentQuery,
  useAssessmentScheduleQuery,
  useAssessmentSlotQuery,
  useAssessmentResultQuery,
  GET_ASSESSMENT_LIST,
} from "..";
import { getSelectedProgram } from "utils";

export const useAssessment = ({ load = false, assessmentMasterId = null }) => {
  const queryClient = useQueryClient();

  const [isSlotInfoOpen, setIsSlotInfoOpen] = useImmer({
    type: "",
    isOpen: false,
    data: null,
  });
  const [selectedSlotId, setSelectedSlotId] = useImmer(null);
  const [assessmentPayload, setAssessmentPayload] = useImmer({
    assessmentId: null,
    scheduleMasterId: null,
  });
  const [scheduleId, setScheduleId] = useImmer(null);

  const isScheduleModalOpen =
    isSlotInfoOpen.isOpen && isSlotInfoOpen.type === "scheduleModal";

  const selectedProgram = getSelectedProgram();
  const studentBatchId = selectedProgram?.studentBatchId;

  const assessmentsQuery = useAssessmentQuery({
    load,
    studentBatchId,
  });

  const assessmentScheduleQuery = useAssessmentScheduleQuery({
    assessmentId: assessmentPayload.assessmentId,
    studentBatchId,
    scheduleMasterId: assessmentPayload.scheduleMasterId,
    isModalOpen: isScheduleModalOpen,
  });
  const assessmentSlotQuery = useAssessmentSlotQuery({
    scheduleId,
    isModalOpen: isScheduleModalOpen,
  });

  const assessmentResultQuery = useAssessmentResultQuery({
    assessmentMasterId,
  });

  const createSchedule = useMutation({
    mutationFn: createAssessmentSchedule,
    onError: (e) => {
      setIsSlotInfoOpen((draft) => {
        draft.isOpen = true;
        draft.type = "unsuccess";
        draft.data = { ...draft.data, message: e.response.data.message };
        return draft;
      });
    },
    onSuccess: (e) => {
      queryClient.invalidateQueries([GET_ASSESSMENT_LIST, studentBatchId]);
      setIsSlotInfoOpen((draft) => {
        draft.isOpen = true;
        draft.type = "success";
        draft.data = { ...draft.data };
        return draft;
      });
    },
  });

  const onAssessmentScheduleModal = ({
    assessmentId = null,
    scheduleMasterId = null,
  }) => {
    setScheduleId(null);
    setSelectedSlotId(null);
    setAssessmentPayload((draft) => {
      draft = {
        assessmentId,
        scheduleMasterId,
      };
      return draft;
    });
    setIsSlotInfoOpen((draft) => {
      draft.isOpen = true;
      draft.type = "scheduleModal";
      draft.data = null;
      return draft;
    });
  };

  return {
    selectedSlotId,
    setSelectedSlotId,
    scheduleId,
    setScheduleId,
    createSchedule,
    assessmentsQuery,
    assessmentScheduleQuery,
    assessmentSlotQuery,
    isSlotInfoOpen,
    setIsSlotInfoOpen,
    onAssessmentScheduleModal,
    assessmentResultQuery,
    studentBatchId,
  };
};
