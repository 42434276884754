import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import { useAuthenticate, useAppScope } from "..";
import SimpleReactValidator from "simple-react-validator";
import { AuthLayout, ModalLayout, PasswordToggle } from "shared";
import InputControl from "shared/components/InputControl";

import loginPageGraphics from "assets/images/login-page-graphics.svg";
import logniPasswordGraphics from "assets/images/login-passwrod-graphics.svg";
import signupFinishGraphics from "assets/images/signup-finish-graphics.svg";
import envelopGraphics from "assets/images/envelop-graphics.svg";
import userAvata from "assets/images/icons/user-avatar.svg";
import warningIcon from "assets/images/warning-graphics.svg";
import { clearLocalStorage, setCookie } from "utils";

export const Login = () => {
  const { AppState, setIsRemember, isRemember } = useAppScope();
  const {
    authenticateState,
    setAuthenticateState,
    isUserEmailExist,
    authenticateUser,
    modalState,
    onCloseModal,
    resendInvitation,
  } = useAuthenticate();
  const { credential, isEmailExist, isInvitation } = authenticateState;
  const { userName, password } = credential;
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [update, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  useEffect(() => {
    sessionStorage.clear();
    clearLocalStorage();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const assessmentId_q = params.get("assessmentId");
  const slotId_q = params.get("slotId");
  const attemptId_q = params.get("attemptId");
  const assessmentToken_q = params.get("assessmentToken");
  const isFromMobileApp = params.get("isFromMobileApp");

  useEffect(() => {
    if (assessmentId_q && slotId_q && assessmentToken_q && isFromMobileApp) {
      return;
    } else if (AppState.token) return navigate("/");
  }, [AppState.token]);

  useEffect(() => {
    if (assessmentId_q && slotId_q && assessmentToken_q && isFromMobileApp) {
      try {
        setCookie(
          "_stu_user_data",
          JSON.stringify({
            refreshToken: "20e5db5b66dc4",
            token: "eyJhbGciOi-HIkR4H_o",
            user: {
              userId: "126c8e",
              firstName: "",
              lastName: "",
              email: "",
            },
          })
        );
        localStorage.setItem("_stu_selectedProgram", true);
        window.location.href = `/program/assessment/start?assessmentId=${assessmentId_q}&slotId=${slotId_q}&attemptId=${attemptId_q}&assessmentToken=${assessmentToken_q}`;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const resendMail = () => {
    resendInvitation.mutate(userName);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if (!isEmailExist) {
        isUserEmailExist.mutate(userName);
        validator.current.hideMessages();
      } else {
        authenticateUser.mutate(credential);
      }
    } else {
      validator.current.showMessages();
      forceUpdate((prev) => prev + 1);
    }
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setAuthenticateState((draft) => {
      draft.credential[name] = value;
    });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleIsRemember = () => {
    setIsRemember((draft) => {
      draft = !draft;
      return draft;
    });
  };

  return (
    <>
      <AuthLayout
        leftDivImage={
          isEmailExist
            ? logniPasswordGraphics
            : isInvitation
            ? signupFinishGraphics
            : loginPageGraphics
        }
      >
        {!isEmailExist && !isInvitation && (
          <form>
            <h2>Welcome to Nergy Vidya</h2>
            <div className="mb-3">
              <label className="form-label">Enter Email Address</label>
              <InputControl
                type="email"
                name="userName"
                value={userName}
                className="form-control"
                onChange={onHandleChange}
                autoComplete="username"
                disabled={isUserEmailExist.isPending}
                invalid={validator.current.message(
                  "Email Address",
                  userName,
                  "required|email"
                )}
              />
              <FormFeedback>
                {validator.current.message(
                  "Email Address",
                  userName,
                  "required|email"
                )}
              </FormFeedback>
            </div>
            <button
              type="submit"
              onClick={(e) => onSubmit(e)}
              className="btn btn-primary"
              disabled={isUserEmailExist.isPending}
            >
              Continue
            </button>
          </form>
        )}
        {isEmailExist && !isInvitation && (
          <form>
            <h2>Welcome to Nergy Vidya</h2>
            <div className="mb-3">
              {userName && (
                <div className="auth-form-user-info d-flex justify-content-start align-items-center">
                  <img src={userAvata} alt="" />
                  <span className="ms-2">{userName}</span>
                </div>
              )}
            </div>
            <p className="form-auth-sm-txt">
              Please enter your registered password
            </p>
            <div className="mb-3 position-relative form-group has-icon-right change_password">
              <label className="form-label">Password</label>
              <InputControl
                type={passwordShown ? "text" : "password"}
                className="form-control"
                name="password"
                placeholder="Password"
                disabled={authenticateUser.isPending}
                value={password}
                onChange={onHandleChange}
                Complete="current-password"
                invalid={validator.current.message(
                  "password",
                  password,
                  "required"
                )}
              />
              <PasswordToggle
                passwordShown={passwordShown}
                togglePassword={togglePassword}
              />
              <FormFeedback>
                {validator.current.message("password", password, "required")}
              </FormFeedback>
            </div>

            <div className="mb-3 d-flex justify-content-between align-items-center">
              <label className="custom-checkbox d-flex justify-content-start align-items-center">
                <input
                  type="checkbox"
                  name="remember"
                  onChange={toggleIsRemember}
                  checked={isRemember}
                />
                <span>Remember me</span>
              </label>
              <Link to="/forgotpassword" className="form-text-link">
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              onClick={(e) => onSubmit(e)}
              className="btn btn-primary"
              disabled={authenticateUser.isPending}
            >
              Log in
            </button>
          </form>
        )}
        {isInvitation && !isEmailExist && (
          <div className="invitation-content">
            <img src={envelopGraphics} alt="" className="block-imgage" />
            <h2>Check your inbox to finish signup</h2>
            <div className="invitation-text">
              <p className="form-auth-sm-txt mb-0">
                We have sent a signup link to you at
                <span> {userName}</span>
              </p>
              <div className="invite-link">
                <a
                  onClick={resendMail}
                  data-disabled={resendInvitation.isPending}
                  className="form-text-link"
                >
                  Resend signup link
                </a>
              </div>
            </div>
          </div>
        )}
      </AuthLayout>

      {modalState.actionFrom === "invalidEmail" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={"Can’t log in"}
          message={modalState?.info}
          onConfirm={onCloseModal}
          onCancel={onCloseModal}
          confirmButtonLabel={"Try Again"}
          icon={warningIcon}
          modalFooter={
            <p>
              Need help? <Link to="/support">Raise a Support Ticket</Link>
            </p>
          }
        />
      )}

      {modalState.actionFrom === "invalidPassword" && (
        <ModalLayout
          isOpen={modalState.isOpen}
          title={"Incorrect Password"}
          message={modalState?.info}
          onConfirm={onCloseModal}
          onCancel={onCloseModal}
          confirmButtonLabel={"OK"}
          icon={warningIcon}
        />
      )}
    </>
  );
};
