import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorFormatter, setCookie } from "utils";
import { useAppScope } from "..";

export const useHandleExternalToken = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAppState } = useAppScope();

  const token = searchParams.get("token");
  const baseURL = process.env.REACT_APP_BASE_URL + "api/";

  useEffect(() => {
    if (!token) return;

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${baseURL}/v2/Accounts/info`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Source: "StudentWeb",
            },
          }
        );
        const { refreshToken, token: newToken, user } = res.data;
        const userData = {
          refreshToken,
          token: newToken,
          user,
          isExternal: true,
        };
        setCookie("_stu_user_data", JSON.stringify(userData));
        setAppState((draft) => userData);
        navigate("/", { replace: true });
      } catch (err) {
        errorFormatter(err);
      }
    };

    fetchData();
  }, [token]);
};
