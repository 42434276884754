import React, { useEffect, useState } from "react";
import { CouseTaskView, useCourseTask } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const CourseTaskDetailsMobileView = () => {
  let { courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    currentCourseTask,
    courseTaskDetails,
    courseType,
    updateCourseTasksStatus,
    onStartSimulation,
    modalState,
    onOpenModal,
    onCloseModal,
    studentSpecificDataQuery,
    studentTasks,
    setCurrentCourseTask,
  } = useCourseTask({ load: true, courseId });

  const { data: taskDetails, isPending } = courseTaskDetails;
  const { title, taskGroup, taskContents, taskSections, description } =
    !!taskDetails && taskDetails;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767 && location.pathname.endsWith("/view")) {
        const newPath = location.pathname.replace("/view", "");
        navigate(newPath);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const newCurrent = studentTasks?.find(
      (e) => e?.courseTaskId === currentCourseTask?.courseTaskId
    );
    if (newCurrent) {
      setCurrentCourseTask(newCurrent);
    }
  }, [studentTasks]);

  return (
    <div className="single-page-content mobile-task-view">
      <div className="container">
        <div className="row"></div>
        <CouseTaskView
          title={title}
          isPending={isPending}
          description={description}
          taskGroup={taskGroup}
          taskContents={taskContents}
          taskSections={taskSections}
          courseType={courseType}
          currentCourseTask={currentCourseTask}
          onStartSimulation={onStartSimulation}
          updateCourseTasksStatus={updateCourseTasksStatus}
          modalState={modalState}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          studentSpecificDataQuery={studentSpecificDataQuery}
          isMobile={true}
        />
      </div>
    </div>
  );
};
