import React from "react";
import { CertificateList } from ".";
import "./style/certificate.styles.css";
import { Route, Routes } from "react-router-dom";

export const Certificate = ({ isAllCertificate = false }) => {
  return (
    <Routes>
      <Route
        element={<CertificateList isAllCertificate={isAllCertificate} />}
        path={"/"}
      />
    </Routes>
  );
};
